import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import styles from './styles';

interface Option {
    label: string;
    value: string | number;
    disabled?: boolean;
    isGroupTitle?: boolean;
    path?: string;
}

interface Props {
    options: Option[] | undefined;
    control: string;
    label?: any;
    value?: any;
    error?: boolean;
    helperText?: string | boolean;
    defaultValue?: string | number;
    formControlClassName?: string;
    shrink?: boolean;
}

const ApSelectField: React.FC<Props & SelectProps> = ({
    control,
    value,
    label = '',
    options,
    error,
    helperText,
    formControlClassName,
    variant,
    shrink,
    disabled,
    fullWidth,
    ...rest
}) => {
    const labelId = `label_${control}`;
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const classes = styles();

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    return (
        <FormControl
            variant={variant || 'outlined'}
            margin="dense"
            error={!!error}
            disabled={disabled}
            fullWidth={fullWidth ?? true}
            className={clsx(classes.formControl, formControlClassName)}
        >
            <InputLabel ref={inputLabel} shrink={shrink} id={labelId} style={{ fontSize: 13 }} variant={variant || 'outlined'}>
                {label}
            </InputLabel>
            <Select
                className={clsx(classes.select)}
                id={control}
                value={value}
                name={control}
                labelId={labelId}
                labelWidth={labelWidth}
                style={{ fontSize: 13 }}
                variant={variant || 'outlined'}
                disabled={disabled}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
                {...rest}
            >
                {options &&
                    options.map((option: Option) => (
                        <MenuItem
                            data-cy={option.label.split(' ').join('')}
                            key={uuidv4()}
                            value={option.value}
                            disabled={option.isGroupTitle || option.disabled}
                            className={clsx(classes.menuItem, option.path ? classes.lastMenuItem : null)}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>

            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default ApSelectField;
