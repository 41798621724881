import axios, { AxiosResponse } from 'axios';
import { GLOBAL } from 'constants/index';
import {
    LoginCredentialsBody,
    LoginCredentialsResponse,
    MessageFromCacheResponse,
    TFACodeDoneBody,
    TFASelectionBody
} from 'types/apiController';
import { NetworkService } from '../index';

class MessageController {
    static async sendLoginCredentials(body: LoginCredentialsBody, entrypointId: string): Promise<AxiosResponse<LoginCredentialsResponse>> {
        return axios.post(GLOBAL.BASE_URL + '/public/message/login-credentials?entryPointId=' + entrypointId, body, {
            headers: { ...NetworkService.getAuthorizationHeader(entrypointId) }
        });
    }

    static async getMessageFromCache(entrypointId: string): Promise<AxiosResponse<MessageFromCacheResponse>> {
        return axios.get(GLOBAL.BASE_URL + '/protected/message', {
            headers: { ...NetworkService.getAuthorizationHeader(entrypointId) }
        });
    }

    static async send2faAuthSelection(body: TFASelectionBody, entrypointId: string): Promise<AxiosResponse<any>> {
        return axios.post(GLOBAL.BASE_URL + '/protected/message/two-fa-selection', body, {
            headers: { ...NetworkService.getAuthorizationHeader(entrypointId) }
        });
    }

    static async send2faCodeDone(body: TFACodeDoneBody, entrypointId: string): Promise<AxiosResponse<any>> {
        return axios.post(GLOBAL.BASE_URL + '/protected/message/two-fa-code', body, {
            headers: { ...NetworkService.getAuthorizationHeader(entrypointId) }
        });
    }

    static async cancelProcess(entrypointId: string): Promise<AxiosResponse<any>> {
        return axios.put(GLOBAL.BASE_URL + '/protected/message/forward-cancel-event-to-node', {
            headers: { ...NetworkService.getAuthorizationHeader(entrypointId) }
        });
    }
}

export default MessageController;
