import postalCodes from 'postal-codes-js';
import { DetermineEmptyValueType, Empty } from 'types';
import { GLOBAL } from 'constants/index';
import { Localisation } from '../index';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Cookies from 'universal-cookie/lib/Cookies';

const NUMBER_PRECISION = 2;

class GlobalServices {
    static isDefined<T>(value: T | undefined | null): value is T {
        return value !== undefined && value !== null;
    }

    static definedValueIsEmpty<T extends number | boolean | string | { [key: string]: any } | any[]>(
        value: T | Empty
    ): value is DetermineEmptyValueType<T> {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        switch (typeof value) {
            case 'object':
                return Object.keys(value).length === 0;
            case 'string':
                return value === '';
            default:
                return false;
        }
    }

    static isEmpty<T extends number | string | { [key: string]: any } | any[]>(value: T | null | boolean | undefined): value is never {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.isDefined(value)) {
            return this.definedValueIsEmpty(value);
        }
        return true;
    }

    static ifExistSet<T>(value: T | null | undefined): T | string {
        if (this.isDefined(value)) {
            return value;
        } else {
            return '';
        }
    }

    static getArrayDifference<T>(sourceArray: T[], compareToArray: T[]) {
        return sourceArray.filter(element => !compareToArray.includes(element));
    }

    static validatePostalCode(countryCode: string, postalCode: string | number): string | boolean {
        if (this.isEmpty(countryCode)) {
            return false;
        }
        return postalCodes.validate(countryCode, postalCode);
    }

    static generateRandomId(): string {
        return 'id' + Math.random().toString(16).slice(2);
    }

    static getBaseURL(): string {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            const port = window.location.port;
            return protocol + '//' + hostname + ':' + port;
        } else {
            return protocol + '//' + hostname;
        }
    }

    static getHumanReadableFileSize(bytes: number, si = false, decimalPoint = 1): string {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** decimalPoint;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(decimalPoint) + ' ' + units[u];
    }

    static getQueryParameterIfItExists(searchParams: URLSearchParams, param: string): string | undefined {
        return searchParams.has(param) ? (searchParams.get(param) as string) : undefined;
    }

    static timeout(delay: number): Promise<void> {
        return new Promise(res => setTimeout(res, delay));
    }

    static toFixedNoRounding(num: number, p = NUMBER_PRECISION): string | number {
        let n = Number.isNaN(num) || !isFinite(num) ? 0 : num;
        n = Math.round((n + Number.EPSILON) * 10000) / 10000;
        n = Number.isNaN(n) ? num : n;
        const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + p + '})?', 'g');
        const a = n.toString().match(reg)![0];
        const dot = a.indexOf('.');
        if (dot === -1) {
            // integer, insert decimal dot and pad up zeros
            return a + '.' + '0'.repeat(p);
        }
        const b = p - (a.length - dot) + 1;
        return b > 0 ? a + '0'.repeat(b) : a;
    }

    static camelize(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    static toCamelCase(s: string) {
        return s.replace(/([-_][a-z])/gi, $1 => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    }

    static keysToCamelCase(obj: Record<string, any>) {
        const n: Record<string, unknown> = {};

        Object.keys(obj).forEach(k => {
            n[GlobalServices.toCamelCase(k)] = obj[k];
        });

        return n;
    }

    static responseCodesErrorHandler(data: any): any {
        return !GlobalServices.isEmpty(data) ? data : Localisation.localize('SERVER_ERROR_PROBLEM_WITH_RESOURCE');
    }

    static getUUID(): string {
        const cookie = new Cookies();
        const uuid = cookie.get(GLOBAL.UUID_COOKIE_KEY);

        if (uuidValidate(uuid)) {
            return uuid;
        } else {
            const generatedUUID = uuidv4();
            cookie.set(GLOBAL.UUID_COOKIE_KEY, generatedUUID);
            return generatedUUID;
        }
    }
}

export default GlobalServices;
