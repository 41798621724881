import { TFAMethods } from 'types';

export const TFA_METHODS_DEBUG: TFAMethods = {
    tfa01: '******567',
    tfa02: '******458'
};

export const TFA_MESSAGES_DEBUG: string[] = ['Please enter your code'];

export const VALID_THEME_KEYS = [
    'logoUrl',
    'favIconUrl',
    'pageTitle',
    'companyName',
    'primaryColor',
    'backgroundColor',
    'errorColor',
    'successColor',
    'primaryTextColor',
    'secondaryTextColor',
    'introductionSection',
    'translation'
];

export const LIGHT_THEME = {
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Logo_de_Free.png',
    companyName: '',
    favIconUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Logo_de_Free.png',
    pageTitle: 'Paragon',
    primaryColor: '#cd1f26',
    backgroundColor: '#fff',
    errorColor: '#ff3344',
    successColor: '#4BB543',
    primaryTextColor: '',
    secondaryTextColor: '',
    introductionSection: [
        {
            title: 'Welcome to Paragon’s digital income verification service',
            description: `<div>We work with APARI - an HMRC partner - to retrieve your SA100 and SA302 directly from HMRC to save you time.\n\nThis information is required to verify your income as part of your recent application.\n\nThis information will only be used for the purpose of this application process.\n\nWe will not store any Government IDs or passwords.\n\nBy clicking the button below, you agree to the <a href='#'>Terms and Conditions</a>  and <a href='#'>Privacy notice.</a> </p> </div> `
        },
        {
            title: 'Welcome to Paragon’s digital income verification service 2',
            description: `
                <p>We work with Apari - an HMRC partner - to retrieve your SA100 and SA302 directly from HMRC to save you time.</p>
                <p>This information is required to verify your income as part of your recent application.</p>
                <p>This information will only be used for the purpose of this application process.</p>
                <p>We will not store any Government IDs or passwords.</p>
                <p>By clicking the button below, you agree to the <a href='#'>Terms and Conditions</a>  and <a href='#'>Privacy notice.</a> </p>`
        }
    ],
    translation: {
        SUCCESS_SCREEN_PARAGRAPH: 'Our team will process this information and be in touch shortly to discuss your application. '
    }
};

// const DARK_THEME = {
//     logo: 'https://material-theme.com/img/logo/material-oceanic.svg',
//     companyName: '',
//     primaryColor: '#009688',
//     secondaryColor: '#121212',
//     backgroundColor: '#121212',
//     errorColor: '#ff3344',
//     successColor: '#4BB543',
//     primaryTextColor: '#fff',
//     secondaryTextColor: 'rgba(255, 255, 255, 0.7)',
//     introductionSection: [
//         {
//             title: 'This is My’s digital income verification service',
//             description: `<div>We work with Apari - an HMRC partner - to retrieve your SA100 and SA302 directly from HMRC to save you time.\n\nThis information is required to verify your income as part of your recent application.\n\nThis information will only be used for the purpose of this application process.\n\nWe will not store any Government IDs or passwords.\n\nBy clicking the button below, you agree to the <a href='#'>Terms and Conditions</a>  and <a href='#'>Privacy notice.</a> </p> </div> `
//         },
//         {
//             title: 'Welcome to Paragon’s digital income verification service 2',
//             description: `<div>We work with Apari - an HMRC partner - to retrieve your SA100 and SA302 directly from HMRC to save you time.\n\nThis information is required to verify your income as part of your recent application.\n\nThis information will only be used for the purpose of this application process.\n\nWe will not store any Government IDs or passwords.\n\nBy clicking the button below, you agree to the <a href='#'>Terms and Conditions</a>  and <a href='#'>Privacy notice.</a> </p> </div> `
//         }
//     ],
//     translation: {
//         SUCCESS_SCREEN_PARAGRAPH: 'Our team will process this information and be in touch shortly to discuss your application. ',
//         WELCOME_SCREEN_NEXT: 'Lets start'
//     }
// };

export const CURRENT_THEME = LIGHT_THEME;
