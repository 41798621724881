import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/es/types';

const useEventTracking = () => {
    const { trackEvent } = useMatomo();
    const trackingEvent = (event: TrackEventParams) => {
        trackEvent(event);
    };

    return { trackingEvent };
};

export default useEventTracking;
