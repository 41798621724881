import { COLORS } from 'constants/index';

export default {
    root: {},
    containedPrimary: {
        color: '#fff'
    },
    outlined: {
        fontSize: '16px !important'
    },
    contained: {
        minHeight: 40,
        height: 'fit-content',
        fontSize: '16px !important',
        borderRadius: 2,
        '&:disabled': {
            color: '#fff',
            background: COLORS.apariBlack20
        }
    },
    outlinedPrimary: {
        minHeight: 40,
        height: 'fit-content',
        fontSize: '16px !important',
        borderRadius: 2,
        borderWidth: '2px !important',
        '&:disabled': {
            color: COLORS.apariBlack20,
            background: 'transparent',
            border: '2px solid ' + COLORS.apariBlack20
        }
    },
    outlinedSecondary: {
        minHeight: 40,
        height: 'fit-content',
        borderRadius: 12,
        border: '1px solid ' + COLORS.paperLight,
        '&:disabled': {
            color: COLORS.paperLight,
            border: '1px solid ' + COLORS.paperLight,
            opacity: 0.5
        }
    },
    outlinedSizeSmall: {
        minHeight: 30,
        height: 'fit-content'
    },
    containedSizeSmall: {
        minHeight: 30,
        height: 'fit-content'
    },
    outlinedSizeLarge: {
        minHeight: 85,
        height: 'fit-content',
        fontSize: '20px !important'
    },
    containedSizeLarge: {
        minHeight: 85,
        height: 'fit-content',
        fontSize: '20px !important'
    },
    text: {
        fontSize: 12,
        fontWeight: 500,
        color: COLORS.apariBlack80,
        '&:hover': {
            background: 'transparent'
        }
    }
};
