import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TextFieldProps } from '@material-ui/core';
import { ApariTextField } from 'components/common/ui';
import clsx from 'clsx';
import styles from './styles';
import { GlobalServices } from 'utils';
import { DATE_FORMAT } from 'constants/index';

const BasicTextComponent = (props: TextFieldProps) => {
    const { disabled, error, helperText, ...rest } = props;
    const classes = styles();

    return (
        <ApariTextField
            error={error}
            helperText={helperText}
            fullWidth={false}
            noShrinkClass
            disabled={disabled}
            classes={{
                root: clsx(classes.root, disabled && classes.disableHover)
            }}
            onChange={e => {
                e.preventDefault();
            }}
            {...rest}
            color="primary"
        />
    );
};

export const ApDatePickerField: React.FC<KeyboardDatePickerProps> = ({
    value,
    format,
    disableToolbar = true,
    TextFieldComponent,
    ...props
}) => {
    const classes = styles();
    const getDateFormat = () => {
        return GlobalServices.isEmpty(format) ? DATE_FORMAT.UK_STANDARD_2 : format;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                autoOk
                disableToolbar={disableToolbar}
                margin="dense"
                format={getDateFormat()}
                value={value}
                InputProps={{
                    className: clsx(classes.textFieldRoot, classes.textFieldRootDarkBackground)
                }}
                InputLabelProps={{
                    shrink: true
                }}
                TextFieldComponent={TextFieldComponent || BasicTextComponent}
                KeyboardButtonProps={{
                    'aria-label': 'change date'
                }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default ApDatePickerField;
