import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import Cookies from 'universal-cookie/lib/Cookies';
import jwt_decode from 'jwt-decode';
import { GLOBAL } from 'constants/index';

const cookie = new Cookies();
const jwt_cookie = cookie.get(GLOBAL.JWT_COOKIE_KEY);

let decoded = '';

if (jwt_cookie) {
    decoded = jwt_decode(jwt_cookie);
}

const instance = createInstance({
    urlBase: GLOBAL.REACT_APP_MATOMO_URL,
    siteId: GLOBAL.REACT_APP_MATOMO_SITEID,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: decoded !== '' ? decoded.id : 'Anonymous' // optional, default value: `undefined`
});

ReactDOM.render(
    <MatomoProvider value={instance}>
        <CssBaseline />
        <App />
    </MatomoProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
