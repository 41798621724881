import { makeStyles } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';

export default makeStyles(theme => ({
    ...globalStyles,
    root: {
        textTransform: 'none'
    },
    marginVertical: {
        marginTop: 5,
        marginBottom: 5
    },
    marginHorizontal: {
        marginLeft: 5,
        marginRight: 5
    },
    outlinedPrimary: {
        borderColor: theme.palette.primary.main,
        borderRadius: 0
    }
}));
