import { makeStyles, Theme, lighten } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles<Theme>(theme => ({
    rowCard: {
        background: lighten(theme.palette.background.default, 0.2),
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        borderRadius: '2px',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        height: 86,
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 70
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        paddingLeft: 7,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 11
        }
    },
    rowCardInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    rowCardLeft: {
        display: 'flex',
        flexDirection: 'row'
    },
    rowCardInfoDate: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        color: COLORS.apariBlack
    },
    rowCardInfoSender: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 12
    },
    rowCardInfoSenderTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        }
    }
}));
