import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import defaultTheme, { generateTheme } from 'styles/theme';
import { CompanyTheme } from 'types';
import { GlobalServices } from 'utils';
import English from 'utils/localisation/I18n/languages/en.json';
import i18n from 'utils/localisation/I18n/I18n';

interface CompanyThemeContextInterface {
    companyTheme?: CompanyTheme;
    setCompanyTheme: React.Dispatch<React.SetStateAction<CompanyTheme>>;
    loadingCompanyTheme: boolean;
    setLoadingCompanyTheme: React.Dispatch<React.SetStateAction<boolean>>;
    customMaterialUITheme: Theme;
}

export const CompanyThemeContext = React.createContext({} as CompanyThemeContextInterface);

export const CompanyThemeProvider: React.FC = ({ children }) => {
    const [companyTheme, setCompanyTheme] = useState<CompanyTheme>();
    const [customMaterialUITheme, setCustomMaterialUITheme] = useState<Theme>(defaultTheme);
    const [loadingCompanyTheme, setLoadingCompanyTheme] = useState<boolean>(true);

    useEffect(() => {
        if (companyTheme) {
            addTranslationResource();
            prepareMaterialUITheme();
            updateFaviconAndTitle();
            setLoadingCompanyTheme(false);
        }
    }, [companyTheme]);

    const addTranslationResource = () => {
        if (companyTheme && !GlobalServices.isEmpty(companyTheme.translation)) {
            i18n.addResourceBundle('en', 'translation', { ...companyTheme.translation });
        } else {
            i18n.addResourceBundle('en', 'translation', { ...English });
        }
    };

    const prepareMaterialUITheme = () => {
        const customPalette = {
            palette: {
                ...defaultTheme.palette,
                primary: {
                    ...(companyTheme && companyTheme.primaryColor
                        ? { main: companyTheme.primaryColor }
                        : { ...defaultTheme.palette.primary })
                },
                background: {
                    ...defaultTheme.palette.background,
                    ...(companyTheme && companyTheme.backgroundColor && { default: companyTheme.backgroundColor })
                },
                text: {
                    primary:
                        companyTheme && companyTheme.primaryTextColor ? companyTheme.primaryTextColor : defaultTheme.palette.text.primary,
                    secondary:
                        companyTheme && companyTheme.secondaryTextColor
                            ? companyTheme.secondaryTextColor
                            : defaultTheme.palette.text.secondary
                },
                error: {
                    ...(companyTheme && companyTheme.errorColor ? { main: companyTheme.errorColor } : { ...defaultTheme.palette.error })
                },
                success: {
                    ...(companyTheme && companyTheme.successColor
                        ? { main: companyTheme.successColor }
                        : { ...defaultTheme.palette.success })
                }
            }
        };
        const customTheme = generateTheme({ ...customPalette });
        setCustomMaterialUITheme(customTheme);
    };

    const updateFaviconAndTitle = () => {
        if (companyTheme.favIconUrl) {
            const favicon: any = document.getElementById('favicon');
            favicon.href = companyTheme.favIconUrl;
        }
        if (companyTheme.pageTitle) {
            const pageTitle: any = document.getElementById('pageTitle');
            pageTitle.innerHTML = companyTheme.pageTitle;
        }
    };

    return (
        <CompanyThemeContext.Provider
            value={{
                companyTheme,
                setCompanyTheme,
                loadingCompanyTheme,
                setLoadingCompanyTheme,
                customMaterialUITheme
            }}
        >
            {children}
        </CompanyThemeContext.Provider>
    );
};
