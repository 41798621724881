import React from 'react';
import clsx from 'clsx';
import styles from './styles';

type Props = {
    className?: string;
    stepsCount: number;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const StepIndicator: React.FC<Props> = ({ className, stepsCount, activeStep, setActiveStep }) => {
    const classes = styles();

    const renderIndicators = () => {
        const preparedIndicators:any[] = [];
        for (let i = 0; i < stepsCount; i++) {
            preparedIndicators.push(
                <div
                    key={i}
                    onClick={() => setActiveStep(i)}
                    className={clsx(classes.indicator, i === activeStep && classes.activeIndicator)}
                />
            );
        }
        return preparedIndicators;
    };

    return <div className={clsx(classes.wrapper, className)}>{renderIndicators().map(indicator => indicator)}</div>;
};

export default StepIndicator;
