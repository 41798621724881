import React from 'react';
import clsx from 'clsx';
import OtpInput, { OtpInputProps } from 'react-otp-input';
import styles from './styles';

const ApariOTPInput: React.FC<OtpInputProps> = props => {
    const classes = styles();
    return (
        <OtpInput data-cy="twoFactorCodeInput" inputStyle={clsx(classes.input)} containerStyle={clsx(classes.inputWrapper)} {...props} />
    );
};

export default ApariOTPInput;
