import React from 'react';
import clsx from 'clsx';
import { Typography, useTheme } from '@material-ui/core';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type ExpandableItemProps = Omit<AccordionProps, 'onChange'> & {
    title: string;
    id: string;
    handleExpansion: (itemId: string, expanded: boolean) => void;
};

const ExpandableItem: React.FC<ExpandableItemProps> = ({ title, id, handleExpansion, children, ...rest }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    const theme = useTheme();

    const onChange = (e: any, expanded: boolean) => {
        handleExpansion(id, expanded);
    };

    return (
        <React.Fragment>
            <Accordion
                elevation={0}
                classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded }}
                className={clsx(globalClasses.fullWidth)}
                onChange={onChange}
                {...rest}
            >
                <AccordionSummary
                    expandIcon={<ExpandIcon primaryColor={theme.palette.primary.main} />}
                    aria-controls={`panel${title}-content`}
                    id={`panel${title}-header`}
                    classes={{ root: classes.accordionSummaryRoot }}
                >
                    <Typography color="textPrimary" className={clsx(classes.title)}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={clsx([classes.details])}>{children}</AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default ExpandableItem;
