import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Modal, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ApariButton } from 'components';
import { AppContext } from 'context/AppContext';
import { CompanyThemeContext } from 'context/CompanyThemeContext';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import styles from './styles';

const pageVariants = {
    initial: {
        opacity: 0,
        transform: 'scale(0.9)'
    },
    in: {
        opacity: 1,
        transform: 'scale(1)'
    },
    out: {
        opacity: 0.7
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
};

const LeaveDialog: React.FC = () => {
    const classes = styles();
    const globalClasses = globalStyles();

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { companyTheme } = useContext(CompanyThemeContext);
    const { leaveDialogState, resetLeave, onLeave, setOpenedHelpCenter } = useContext(AppContext);
    const { open, left, outsideUrl, goBackTo } = leaveDialogState;

    const localizeKey = left ? 'LEAVE_DIALOG_LEFT_' : 'LEAVE_DIALOG_';

    const onOk = () => {
        if (left) {
            goBackTo && history.replace(goBackTo);
            resetLeave();
        } else {
            window.open(outsideUrl, '_blank');
            setTimeout(() => {
                onLeave();
            }, 500);
        }
    };

    const onCancel = () => {
        if (left) {
            setOpenedHelpCenter(true);
            setTimeout(() => {
                resetLeave();
                goBackTo && history.replace(goBackTo);
            }, 1000);
        } else {
            resetLeave();
        }
    };

    return (
        <Modal open={open} hideBackdrop={true}>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className={clsx(classes.root, globalClasses.flexColumnCenter)}
            >
                {companyTheme && companyTheme.logoUrl && (
                    <div className={clsx(globalClasses.flexColumnCenter)}>
                        <img alt="logo" src={companyTheme.logoUrl} height={75} />
                    </div>
                )}
                <div className={clsx(globalClasses.flexColumnCenter, globalClasses.flex1)}>
                    <Typography color="textPrimary" className={clsx(classes.smallTitle)}>
                        {Localisation.localize(`${localizeKey}TITLE`)}
                    </Typography>
                    <Typography color="textPrimary" className={clsx(classes.paragraph)}>
                        {Localisation.localize(`${localizeKey}PARAGRAPH`)}
                    </Typography>
                </div>
                <div className={clsx(classes.buttonContainer, globalClasses.flexColumn)}>
                    <ApariButton
                        className={clsx(globalClasses.marginBottom30)}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size={isMobile ? 'medium' : 'large'}
                        onClick={onOk}
                    >
                        {Localisation.localize(`${localizeKey}OK`)}
                    </ApariButton>
                    <ApariButton variant="outlined" color="primary" fullWidth size={isMobile ? 'medium' : 'large'} onClick={onCancel}>
                        {Localisation.localize(`${localizeKey}CANCEL`)}
                    </ApariButton>
                </div>
            </motion.div>
        </Modal>
    );
};
export default LeaveDialog;
