import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: 5,
        borderRadius: 2
    },
    indicator: {
        cursor: 'pointer',
        height: 5,
        width: 15,
        backgroundColor: COLORS.apariGray2,
        borderRadius: 2,
        marginLeft: 8,
        marginRight: 8
    },
    activeIndicator: {
        width: 36,
        backgroundColor: theme.palette.primary.main,
        webkitTransition: 'width 0.5s ease-in-out, background-color 0.5s ease-in-out',
        transition: 'width 0.5s ease-in-out, background-color 0.5s ease-in-out'
    }
}));
