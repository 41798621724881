export enum ScreenTypes {
    LOADING = 'LOADING',
    SIGN_IN_FORM = 'SIGN_IN_FORM',
    CHOSE_METHOD = 'CHOSE_METHOD',
    ENTER_CODE = 'ENTER_CODE',
    ERROR = 'ERROR',
    SOURCE_ERROR = 'SOURCE_ERROR',
    SUCCESS = 'SUCCESS'
}

export enum ScreenRoutes {
    SIGN_IN_FORM = '/authentication/sign-in',
    CHOSE_METHOD = '/authentication/method',
    ENTER_CODE = '/authentication/code',
    ERROR = '/authentication/error',
    SOURCE_ERROR = '/authentication/source-error',
    SUCCESS = '/authentication/success'
}

export enum Mode {
    DEBUG = 'DEBUG',
    PRODUCTION = 'PRODUCTION'
}

export type TFAMethods = {
    [key: string]: string;
};
