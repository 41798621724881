import globalAxios from 'axios';
import { GLOBAL } from 'constants/index';
import { GlobalServices, Localisation } from 'utils';

const setupAxios = (): void => {
    globalAxios.interceptors.request.use(
        (config: any) => {
            if (GLOBAL.NODE_ENV === 'development') {
                config.url = config.url.replace('http://localhost:8000', GLOBAL.BASE_URL);
            } else {
                config.url = config.url.replace('http://localhost:8000', window.location.origin);
            }
            return config;
        },
        (error: any) => {
            Promise.reject(error);
        }
    );

    globalAxios.interceptors.response.use(
        (request: any) => {
            return request;
        },
        async (error: any) => {
            const { response } = error;
            if (response) {
                const { data } = response;
                return Promise.reject(GlobalServices.responseCodesErrorHandler(data));
            } else {
                return Promise.reject(Localisation.localize('SERVER_ERROR_PROBLEM_WITH_RESOURCE'));
            }
        }
    );
};

export default setupAxios;
