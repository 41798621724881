import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { FullScreenLoading } from 'components/index';
import { GovAuthenticationProvider } from 'context/GovAuthenticationContext';
import { QUERY_PARAMS } from 'constants/index';
import useQuery from 'hooks/useQuery';
import { GlobalServices, Localisation, LocalStorageServices } from 'utils';

const SignInGovScreen = lazy(() => import('containers/pages/SignInGovScreen'));
const WelcomeScreen = lazy(() => import('containers/pages/WelcomeScreen'));
import NotFoundScreen from 'containers/pages/NotFoundScreen';

type Props = {
    authenticated?: boolean;
    acceptedTermsAndConditions?: boolean;
};

const newPublicRoutes = ['/:entrypointId/authentication', '/'];

const AppRouter: React.FC<Props> = () => {
    const { trackPageView } = useMatomo();
    const location = useLocation();

    const { queryValues } = useQuery(location, [
        QUERY_PARAMS.UTM_SOURCE,
        QUERY_PARAMS.UTM_MEDIUM,
        QUERY_PARAMS.UTM_CAMPAIGN,
        QUERY_PARAMS.UTM_TERM,
        QUERY_PARAMS.UTM_CONTENT,
        QUERY_PARAMS.GCLID,
        QUERY_PARAMS.FCLID
    ]);

    useEffect(() => {
        if (!GlobalServices.isEmpty(queryValues)) {
            LocalStorageServices.setJSON('UTM_PARAMETERS', queryValues);
        }
    }, []);

    useEffect(() => {
        trackPageView({
            documentTitle: location.pathname
        });
    }, [location]);

    return (
        <AnimatePresence>
            <Suspense fallback={<FullScreenLoading />}>
                <Switch>
                    <Redirect
                        exact
                        from="/"
                        to={{
                            pathname: '/authentication/error',
                            state: { missingEntrypointId: true, message: Localisation.localize('ERROR_SCREEN_MISSING_ENTRYPOINT') }
                        }}
                    />
                    <Route path={newPublicRoutes}>
                        <GovAuthenticationProvider>
                            <Route exact path="/:entrypointId" component={WelcomeScreen} />
                            <Route path="/:entrypointId/authentication" component={SignInGovScreen} />
                            <Route path="/authentication" component={SignInGovScreen} />
                        </GovAuthenticationProvider>
                    </Route>
                    <Route path="*" exact={true} component={NotFoundScreen} />
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default AppRouter;
