declare const process: {
    env: {
        NODE_ENV: string;
        REACT_APP_VERSION: string;
        REACT_APP_BASE_URL: string;
        REACT_APP_JWT_COOKIE_KEY: string;
        REACT_APP_UUID_COOKIE_KEY: string;
        REACT_APP_MATOMO_URL: string;
        REACT_APP_MATOMO_SITEID: number;
        REACT_APP_AES_KEY: string;
        MFA_TYPE: string;
        MFA_TIMESTAMP: string;
        MFA_REFERENCE: string;
        REACT_APP_COOKIE_DOMAIN: string;
        REACT_APP_DISABLE_ORIGIN_CHECK_IFRAME: string;
    };
};

const GLOBAL = {
    VERSION: process.env.REACT_APP_VERSION,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    JWT_COOKIE_KEY: process.env.REACT_APP_JWT_COOKIE_KEY,
    UUID_COOKIE_KEY: process.env.REACT_APP_UUID_COOKIE_KEY,
    TOP_MARGIN: 72,
    REACT_APP_MATOMO_URL: process.env.REACT_APP_MATOMO_URL,
    REACT_APP_MATOMO_SITEID: process.env.REACT_APP_MATOMO_SITEID,
    MFA_TYPE: 'APARI_MFA_TYPE',
    MFA_TIMESTAMP: 'APARI_MFA_TIMESTAMP',
    MFA_REFERENCE: 'APARI_MFA_REFERENCE',
    AES_KEY: process.env.REACT_APP_AES_KEY,
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
    DISABLE_ORIGIN_CHECK_IFRAME: process.env.REACT_APP_DISABLE_ORIGIN_CHECK_IFRAME
};

export default GLOBAL;
