import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack20
        },
        '& .MuiInputBase-root.Mui-disabled': {
            borderColor: COLORS.apariBlack20,
            color: COLORS.apariBlack20
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 10
        }
    },
    disableHover: {
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.apariBlack20
        }
    },
    textFieldRoot: {
        '& label': {
            marginLeft: '-3px'
        },
        color: theme.palette.primary.main,
        borderColor: 'black',
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack20 + ' !important'
        },
        '& input': {
            '&::placeholder': {
                color: theme.palette.grey[400],
                opacity: 1
            }
        }
    },
    textFieldRootDarkBackground: {
        '& input': {
            color: COLORS.paperLight,
            '&::placeholder': {
                opacity: 0.6
            }
        },
        '& .Mui-error input': {
            color: theme.palette.error.main
        }
    },
    inputLabelShrink: {
        background: COLORS.darkPurple,
        padding: 4,
        borderRadius: 2,
        transform: 'translate(11px, -12px) scale(0.75) !important'
    },
    inputLabelFocused: {
        color: COLORS.paperLight + ' !important'
    }
}));
