import queryString from 'query-string';
import { QUERY_PARAMS } from 'constants/index';

export default function useURLSearch(location: any = {}, queryParams?: Array<QUERY_PARAMS>) {
    const { search } = location;
    const searchValues = queryString.parse(search);

    const queryValues = extractUsefulQueryParams(searchValues);

    function extractUsefulQueryParams(searchValues: any) {
        const tempObj = {};

        if (queryParams) {
            queryParams.forEach(item => {
                if (Object.prototype.hasOwnProperty.call(searchValues, item)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    tempObj[item] = searchValues[item];
                }
            });

            return tempObj;
        } else {
            return searchValues;
        }
    }

    return {
        queryValues
    };
}
