import { makeStyles, Theme } from '@material-ui/core';

type Props = {
    noWidthRestriction?: boolean;
    addHeight?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    root: ({ noWidthRestriction, addHeight }) => {
        return {
            [theme.breakpoints.up('lg')]: {
                maxWidth: noWidthRestriction ? '100%' : 780,
                width: '100%',
                position: 'relative'
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: addHeight ? '100%' : '',
                position: 'relative'
            }
        };
    },
    centerContentVertically: {
        [theme.breakpoints.up('lg')]: {
            marginTop: 'auto',
            marginBottom: 'auto',
            paddingBottom: 72
        }
    }
}));
