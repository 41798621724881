import React, { useContext, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { LinearProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Notifications, ApariOfflineStatus } from 'components';
import { AppContext, AppProvider } from 'context/AppContext';
import { CompanyThemeContext, CompanyThemeProvider } from 'context/CompanyThemeContext';
import { createBrowserHistory } from 'history';
import { AppRouter } from 'routers';
import setupAxios from 'services/axios';
import './App.css';

const history = createBrowserHistory();

function App() {
    const { loadingBar } = useContext(AppContext);
    const { customMaterialUITheme } = useContext(CompanyThemeContext);

    const { pushInstruction } = useMatomo();

    pushInstruction('enableJSErrorTracking');

    useEffect(() => {
        setupAxios();
    }, []);

    return (
        <ThemeProvider theme={customMaterialUITheme}>
            <Router history={history}>
                <div className="app-loader">{loadingBar && <LinearProgress variant="query" color="primary" />}</div>
                <ApariOfflineStatus />
                <AppRouter />
                <Notifications />
            </Router>
        </ThemeProvider>
    );
}

const Root = (): React.ReactElement => {
    return (
        <AppProvider>
            <CompanyThemeProvider>
                <App />
            </CompanyThemeProvider>
        </AppProvider>
    );
};

export default Root;
