import { CompanyTheme } from './app';

export enum MessageFromCacheType {
    V1AuthenticationFlowEventNextStep2FASelection = 'V1AuthenticationFlowEventNextStep2FASelection',
    V1AuthenticationFlowEventNextStep2FACode = 'V1AuthenticationFlowEventNextStep2FACode',
    V1AuthenticationFlowEventNextStepSuccess = 'V1AuthenticationFlowEventNextStepSuccess',
    V1AuthenticationFlowEventNextStepError = 'V1AuthenticationFlowEventNextStepError',
    SOURCE_UNAVAILABLE = 'SOURCE_UNAVAILABLE'
}

export type MessageFromCacheResponse = {
    createdAt: string;
    payload: any;
    type: MessageFromCacheType;
};

export type LoginCredentialsBody = {
    username: string;
    password: string;
    metadata?: Record<string, string>;
};

export type LoginCredentialsResponse = {
    jwt: string | null;
    error: {
        message: string;
        errorCode: LoginCredentialsErrorCode;
    };
};

export enum LoginCredentialsErrorCode {
    NO_FREE_NODE = 'NO_FREE_NODE'
}

export type TFASelectionBody = {
    the2Fa: string;
};

export type TFACodeDoneBody = {
    code: string;
};

export type Choice = {
    id?: string;
    text?: string;
};

type Error = {
    errorCode: string;
    message: string;
};

export type EntrypointValidateResponse = {
    jwt: string;
    error: Error | null;
    theme: CompanyTheme;
};
