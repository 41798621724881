export enum NotificationTypes {
    ERROR,
    WARNING,
    INFO,
    SUCCESS
}

export type OpenNotificationsPayload = {
    type: NotificationTypes;
    message: string;
    title?: string;
    onClose?: () => void;
};

export type IntroductionSection = {
    title: string;
    description: string;
};

export type LeaveDialogState = {
    outsideUrl: string;
    goBackTo: string;
    left: boolean;
    open: boolean;
};

export type CompanyTheme = {
    logoUrl: string;
    favIconUrl?: string;
    pageTitle?: string;
    primaryColor?: string;
    primaryTextColor?: string;
    secondaryTextColor?: string;
    backgroundColor?: string;
    errorColor?: string;
    successColor?: string;
    introductionSection: IntroductionSection[];
    translation?: Record<string, string>;
};
