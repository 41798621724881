import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(theme => ({
    backIconContainer: {
        width: '100%',
        maxWidth: 512,
        alignSelf: 'center',
        padding: '0 16px'
    },
    helpIcon: {
        padding: 4,
        '& svg': {
            fontSize: 37
        }
    },
    closeIcon: {
        '& svg': {
            color: theme.palette.text.primary,
            fontSize: 29
        }
    }
}));
