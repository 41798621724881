class UIServices {
    static isMobile(width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): boolean {
        switch (width) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return true;
            case 'lg':
                return false;
            case 'xl':
                return false;
            default:
                return false;
        }
    }
}

export default UIServices;
