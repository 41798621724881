import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    control: {
        borderColor: theme.palette.primary.main,
        width: '100%',
        padding: 0,
        '& .MuiFormControlLabel-root.Mui-disabled': {
            opacity: 0.5
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: '-8px',
            marginRight: 0
        }
    },
    labelMargin: {
        marginLeft: theme.spacing(2)
    },
    cardChecked: {
        background: theme.palette.primary.main
    },
    checkboxIcon: {
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    checkboxCheckedIcon: {
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        fill: 'none',
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main
    }
}));
