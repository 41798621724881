import React from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from '@material-ui/core';
import styles from './styles';
import { GlobalServices } from 'utils';
import useEventTracking from 'hooks/useEventTracking';
import { EventActions } from 'types/eventTracking';

type Props = ButtonProps & {
    marginHorizontal?: boolean;
    marginVertical?: boolean;
    trackingCategory?: string;
    trackingName?: string;
};

const ApariButton: React.FC<Props> = ({
    children,
    className,
    marginVertical,
    type,
    marginHorizontal,
    onClick,
    trackingName,
    trackingCategory,
    ...rest
}) => {
    const { marginVertical: marginVerticalClass, marginHorizontal: marginHorizontalClass, ...otherClasses } = styles();
    const { trackingEvent } = useEventTracking();

    const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if ((type === 'submit' || GlobalServices.isDefined(onClick)) && GlobalServices.isDefined(trackingCategory)) {
            trackingEvent({ category: trackingCategory, action: EventActions.CLICK, name: trackingName });
        }
        if (GlobalServices.isDefined(onClick)) {
            onClick(event);
        }
    };
    return (
        <Button
            className={clsx(marginVertical ? marginVerticalClass : '', marginHorizontal ? marginHorizontalClass : '', className)}
            classes={otherClasses}
            onClick={clickHandler}
            type={type}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default ApariButton;
