import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';
import { ApariTextField } from 'components';

type Props = TextFieldProps &
    NumberFormatProps & {
        darkBackground?: boolean;
        maxLength?: number;
        formatType?: 'string' | 'target' | 'number';
    };

type NumberFormatIntegerProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: (value: number | undefined) => void;
    name: string;
};
type NumberFormatIntegerAsStringExtended = NumberFormatProps & {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: (value: string | undefined) => void;
    name: string;
};

type Target = {
    target: {
        value?: number | string;
        name?: string;
    };
};

type NumberFormatTargetProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: (target: Target) => void;
    name: string;
};

const NumberFormatIntegerAsString: React.FC<NumberFormatIntegerAsStringExtended> = props => {
    const { inputRef, name, onChange, maxLength, ...other } = props;
    return (
        <NumberFormat
            {...other}
            name={name}
            getInputRef={inputRef}
            decimalSeparator={undefined}
            onValueChange={values => {
                onChange(values.value);
            }}
            maxLength={maxLength}
            allowLeadingZeros={true}
            allowNegative={false}
            isNumericString
        />
    );
};

const NumberFormatInteger: React.FC<NumberFormatIntegerProps> = props => {
    const { inputRef, name, onChange, maxLength, ...other } = props;
    return (
        <NumberFormat
            {...other}
            name={name}
            decimalSeparator={undefined}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange(values.floatValue);
            }}
            maxLength={maxLength}
            allowNegative={false}
            isNumericString
        />
    );
};

const TargetNumberFormat: React.FC<NumberFormatTargetProps> = props => {
    const { onChange, name, inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalSeparator={undefined}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        name: name
                    }
                });
            }}
            allowLeadingZeros={true}
            allowNegative={false}
            isNumericString
        />
    );
};

const ApariIntegerField: React.FC<Props> = props => {
    const { type, label, placeholder, InputProps, darkBackground, maxLength, formatType, disabled, ...rest } = props;
    const classes = styles();

    const getFormatType = () => {
        switch (formatType) {
            case 'string':
                return NumberFormatIntegerAsString as any; // eslint-disable-line @typescript-eslint/no-explicit-any
            case 'target':
                return TargetNumberFormat as any; // eslint-disable-line @typescript-eslint/no-explicit-any
            case 'number':
            default:
                return NumberFormatInteger as any; // eslint-disable-line @typescript-eslint/no-explicit-any
        }
    };

    return (
        <ApariTextField
            label={label}
            placeholder={placeholder || ''}
            darkBackground={darkBackground}
            InputProps={{
                ...InputProps,
                inputComponent: getFormatType()
            }}
            disabled={disabled}
            inputProps={{ maxLength: maxLength }}
            type={type || 'string'}
            classes={{
                root: clsx(classes.root, disabled && classes.disableHover)
            }}
            className={clsx(classes.inputAdornment, classes.textField, darkBackground && classes.darkInputAdornment)}
            {...rest}
        />
    );
};
export default ApariIntegerField;
