const COLORS = {
    mainPurple: '#5000D0',
    darkPurple: '#635E76',
    apariLightBlue: '#673FFA',
    paperLight: '#F9F9F9',
    backgroundLight: '#fff',
    backgroundDark: '#fff',
    gray3: '#828282',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#F2F2F2',
    gray7: '#DADADA',
    gray8: '#999999',
    apariGray: '#CCCCCC',
    apariGray2: '#C4C4C4',
    apariGray3: '#666666',
    apariBlack: 'rgba(0, 0, 0, 1)',
    apariBlack80: 'rgba(0, 0, 0, 0.8)',
    apariBlack60: 'rgba(0, 0, 0, 0.6)',
    apariBlack50: 'rgba(0, 0, 0, 0.5)',
    apariBlack40: 'rgba(0, 0, 0, 0.4)',
    apariBlack20: 'rgba(0, 0, 0, 0.2)',
    apariBlack10: 'rgba(0, 0, 0, 0.1)',
    apariOrange: 'rgba(242, 153, 74, 1)',
    apariGreen: 'rgba(33, 150, 83, 1)',
    apariWhite: 'rgba(255, 255, 255, 1)',
    apariSemanticOrange: 'rgba(244, 169, 105, 1)',
    apariSemanticGreen: 'rgba(59, 194, 117, 1)',
    apariFinalisedGreen: '#38B85B',
    apariLightPurple: 'rgba(190, 72, 255, 1)',
    apariLightSilver: '#E6EAF1',
    apariDarkSilver: 'rgba(216, 222, 235, 1)',
    apariWarning: '#FF963D',
    googleButtonRed: '#EA4335',
    apariPurple: 'rgba(105, 65, 250, 1)',
    apariPurple2: '#673FFA',
    singleSignOnButton: 'rgba(249, 249, 249, 1)',
    black: '#000000',
    transparentLight: 'rgba(99, 94, 118, 0.1)',
    backgroundTransparentWhite: 'rgba(255, 255, 255, 0.85)',
    transparentWhite: 'rgba(255, 255, 255, 0.0)',
    red: '#EB5757',
    error: '#FF004C',
    green: '#52AD79',
    mainText: '#635E76',
    darkText: '#828282',
    apariDarkGrey: '#E3E7ED',
    apariOverviewPurple: '#BB48FB'
};

export default COLORS;
