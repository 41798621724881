import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
    authorized: boolean;
    acceptedTermsAndConditions: boolean;
    component: React.ElementType;
};

const AuthRoute: React.FC<Props> = props => {
    const { authorized, acceptedTermsAndConditions, component: C, ...rest } = props;

    return (
        <Route
            {...rest}
            render={props =>
                authorized ? (
                    acceptedTermsAndConditions ? (
                        <C {...props} />
                    ) : (
                        <Redirect to={'/accept-terms-and-conditions'} />
                    )
                ) : (
                    <Redirect to={'/sign-in'} />
                )
            }
        />
    );
};

export default AuthRoute;
