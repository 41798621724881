import React from 'react';
import clsx from 'clsx';
import { FormControlLabel } from '@material-ui/core';
import { FormikContextType } from 'formik';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox } from 'components';
import styles from './styles';

type Props = {
    control: string;
    formik: FormikContextType<any>;
    disabled?: boolean;
    primaryBorderColor?: boolean;
    invertedColors?: boolean;
    darkBackground?: boolean;
    className?: string;
    labelClassName?: string;
    longLabel?: boolean;
    text?: string;
    customLabel?: string | React.ReactNode;
};

const ApCheckbox: React.FC<Props> = props => {
    const { text, darkBackground, formik, control, disabled, className, longLabel, customLabel, labelClassName, ...rest } = props;
    const classes = styles();
    const globalClasses = globalStyles();
    return (
        <div className={clsx(globalClasses.flexRowStart, classes.control, className)}>
            <FormControlLabel
                disabled={disabled}
                control={
                    <ApariCheckbox
                        checked={formik.values[control]}
                        onChange={() => formik.setFieldValue(control, !formik.values[control])}
                        disabled={disabled}
                        {...rest}
                    />
                }
                className={clsx(longLabel && globalClasses.alignItemsFlexStart)}
                label={
                    <p
                        className={clsx(
                            globalClasses.flexColumnCenter,
                            classes.labelMargin,
                            darkBackground ? globalClasses.font13weight600Light : globalClasses.font13weight600Dark,
                            labelClassName
                        )}
                    >
                        {customLabel ? customLabel : text}
                    </p>
                }
            />
        </div>
    );
};

export default ApCheckbox;
