import React from 'react';
import clsx from 'clsx';
import { IconButtonProps, IconButton, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { QuestionMarkIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type BackButtonProps = {
    closeIcon?: IconButtonProps;
    helpIcon?: IconButtonProps;
};

const BackButton: React.FC<BackButtonProps> = ({ closeIcon, helpIcon }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const theme = useTheme();

    return (
        <div className={clsx(classes.backIconContainer, globalClasses.flexRowSpaceBetween)}>
            {closeIcon ? (
                <IconButton className={clsx(classes.closeIcon)} {...closeIcon}>
                    <ArrowBackIcon />
                </IconButton>
            ) : (
                <div />
            )}
            {helpIcon && (
                <IconButton color="primary" className={clsx(classes.helpIcon)} {...helpIcon}>
                    <QuestionMarkIcon primaryColor={theme.palette.primary.main} />
                </IconButton>
            )}
        </div>
    );
};

export default BackButton;
