import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowBackIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
        </SvgIcon>
    );
};

export const NavigationIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 18 12" {...props}>
            <path d="M0 2V0H18V2H0ZM0 7H18V5H0V7ZM0 12H18V10H0V12Z" />
        </SvgIcon>
    );
};

export const MainImageSvg: React.FC<
    SvgIconProps & {
        primaryColor: string;
    }
> = ({ primaryColor, ...props }) => {
    return (
        <SvgIcon fill="none" viewBox="0 0 172 183" {...props}>
            <path
                d="M86.496 182.998a33.699 33.699 0 01-8.373-1.087l-.539-.15-.5-.252c-18.075-9.096-33.324-21.11-45.323-35.71a135.313 135.313 0 01-22.885-40.786 157.507 157.507 0 01-8.844-55.3c.007-.395.014-.7.014-.91 0-9.147 5.058-17.172 12.887-20.446C18.925 25.85 73.325 3.424 77.256 1.803c7.402-3.722 15.3-.615 16.563-.072 2.835 1.164 53.125 21.809 63.992 27.003C169.012 34.087 172 43.704 172 48.542c0 21.905-3.78 42.376-11.234 60.846a140.973 140.973 0 01-25.226 40.805c-20.593 23.259-41.19 31.505-41.39 31.578a22.45 22.45 0 01-7.654 1.227zm-4.844-12.044c1.785.402 5.897 1.005 8.577.024 3.404-1.246 20.644-10.219 36.755-28.416 22.259-25.142 33.553-56.748 33.57-93.94-.04-.753-.573-6.127-7.664-9.515-10.657-5.094-62.929-26.547-63.456-26.763l-.144-.062c-1.096-.46-4.582-1.43-6.985-.167l-.481.225c-.583.24-58.33 24.046-64.489 26.622-4.308 1.801-5.843 6.265-5.843 9.841 0 .262-.007.642-.016 1.133-.492 25.448 5.375 88.067 70.175 121.018z"
                fill="#3F3D56"
            />
            <path
                d="M79.136 7.273s-58.431 23.95-64.692 26.554c-6.26 2.603-9.39 8.851-9.39 15.1 0 6.248-4.696 87.776 74.082 127.195 0 0 7.15 1.975 12.574 0C97.135 174.148 166 140.825 166 48.666c0 0 0-9.372-10.956-14.579-10.956-5.206-63.925-26.814-63.925-26.814s-6.505-2.864-11.983 0z"
                fill={primaryColor}
            />
            <path opacity={0.1} d="M85 26v128S26.486 125.628 27.003 51.037L85 26z" fill="#000" />
            <path d="M74.812 118L56 93.816l10.94-8.507 8.907 11.45L105.938 65 116 74.532 74.812 118z" fill="#fff" />
        </SvgIcon>
    );
};

export const QuestionMarkIcon: React.FC<
    SvgIconProps & {
        primaryColor: string;
    }
> = ({ primaryColor, ...props }) => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 37 37" {...props}>
            <circle cx={18.5} cy={18.5} r={18.5} fill={primaryColor} />
            <path
                d="M17.153 20.91v-.086c.01-.905.104-1.624.284-2.16.18-.534.436-.968.767-1.299a6.19 6.19 0 011.194-.916c.279-.17.53-.372.752-.604.223-.237.398-.509.526-.817a2.56 2.56 0 00.199-1.022c0-.464-.11-.867-.327-1.208a2.194 2.194 0 00-.873-.788 2.65 2.65 0 00-1.215-.277c-.388 0-.762.08-1.122.241-.36.161-.66.415-.902.76-.242.346-.381.798-.42 1.357h-1.789c.038-.805.246-1.494.625-2.067a3.764 3.764 0 011.513-1.314c.63-.303 1.328-.454 2.095-.454.833 0 1.558.165 2.173.497a3.56 3.56 0 011.435 1.364c.34.577.511 1.235.511 1.974 0 .52-.08.992-.241 1.413a3.482 3.482 0 01-.682 1.13 4.677 4.677 0 01-1.065.88c-.417.26-.75.535-1.002.824-.25.284-.433.623-.547 1.016-.113.393-.175.883-.184 1.47v.085h-1.705zm.91 4.204a1.23 1.23 0 01-.903-.377 1.23 1.23 0 01-.376-.902c0-.35.125-.65.376-.902a1.23 1.23 0 01.902-.376c.35 0 .651.125.902.376s.377.552.377.902c0 .232-.06.445-.178.64-.114.194-.267.35-.462.468-.189.114-.402.17-.639.17z"
                fill="#fff"
            />
        </SvgIcon>
    );
};

export const CircleCloseIcon: React.FC<
    SvgIconProps & {
        primaryColor: string;
        fillColor: string;
    }
> = ({ primaryColor, fillColor, ...props }) => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 26 27" {...props}>
            <circle cx={13} cy={13.6025} r={12.5} stroke={primaryColor} fill={fillColor} />
            <path d="M7.996 8.598l10.213 10.214m-10.213 0L18.209 8.598" stroke={primaryColor} strokeWidth={2} />
        </SvgIcon>
    );
};

export const ExpandIcon: React.FC<
    SvgIconProps & {
        primaryColor: string;
    }
> = ({ primaryColor, ...props }) => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 27 28" {...props}>
            <path d="M14 20.172L5.34 9.062h17.32L14 20.171z" fill={primaryColor} />
        </SvgIcon>
    );
};
