import { makeStyles } from '@material-ui/core';
import { FONTS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        outline: 0,
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        top: 0,
        padding: '8px 16px 32px 16px',
        background: theme.palette.background.default
    },
    smallTitle: {
        fontFamily: FONTS.PRIMARY_FONT,
        fontSize: 24,
        fontWeight: 400,
        color: theme.palette.text.primary,
        marginTop: 16,
        marginBottom: 40,
        textAlign: 'center'
    },
    paragraph: {
        fontFamily: FONTS.PRIMARY_FONT,
        fontSize: 20,
        fontWeight: 400,
        color: theme.palette.text.primary,
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 25,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 15
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
            marginBottom: 10
        }
    },
    buttonContainer: {
        width: '100%',
        maxWidth: 512
    }
}));
