import React from 'react';
import { MotionProps, motion } from 'framer-motion';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

const pageVariantsNext = {
    initial: {
        opacity: 0.7,
        x: 100
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0.7
    }
};

const pageVariantsBack = {
    initial: {
        opacity: 0.7,
        x: -100
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0.7
    }
};

const pageVariantsZoomIn = {
    initial: {
        opacity: 0,
        transform: 'scale(0.9)'
    },
    in: {
        opacity: 1,
        transform: 'scale(1)'
    },
    out: {
        opacity: 0.7
    }
};

const pageVariantsZoomOut = {
    initial: {
        opacity: 0.7,
        transform: 'scale(1.2)'
    },
    in: {
        opacity: 1,
        transform: 'scale(1)'
    },
    out: {
        opacity: 0.7
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
};

type Props = MotionProps &
    React.HTMLAttributes<HTMLDivElement> & {
        animate?: boolean;
        isBack?: boolean;
        zoomIn?: boolean;
        zoomOut?: boolean;
    };

const AnimatedStepWrapper: React.FC<Props> = ({ children, className, animate, zoomIn, zoomOut, isBack }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    const getPageVariants = () => {
        if (zoomIn) {
            return pageVariantsZoomIn;
        } else if (zoomOut) {
            return pageVariantsZoomOut;
        } else if (isBack) {
            return pageVariantsBack;
        } else {
            return pageVariantsNext;
        }
    };

    return (
        <motion.div
            className={clsx(globalClasses.fullWidth, globalClasses.fullHeight, classes.root, className)}
            {...(animate && {
                initial: 'initial',
                animate: 'in',
                exit: 'out',
                variants: getPageVariants(),
                transition: pageTransition
            })}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedStepWrapper;
