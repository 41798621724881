import React, { useContext } from 'react';
import clsx from 'clsx';
import { IconButton, makeStyles, Snackbar, SnackbarCloseReason, useTheme } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import { FONTS } from 'constants/index';
import { AppContext } from 'context/AppContext';
import { CircleCloseIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import { NotificationTypes } from 'types';

const styles = makeStyles(theme => ({
    root: {
        padding: '20px 24px',
        width: 340
    },
    filledInfo: {
        backgroundColor: theme.palette.background.default
    },
    titleRoot: {
        fontFamily: FONTS.TERTIARY_FONT,
        paddingTop: 4,
        marginBottom: 20,
        color: theme.palette.text.primary
    },
    message: {
        fontFamily: FONTS.TERTIARY_FONT,
        padding: 0,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    icon: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 17
        }
    },
    action: {
        alignItems: 'flex-start'
    }
}));

const CustomAlert = (props: AlertProps) => {
    const { children, onClose, ...rest } = props;
    const globalClasses = globalStyles();
    const classes = styles();
    const theme = useTheme();

    return (
        <Alert
            classes={{
                root: classes.root,
                message: classes.message,
                icon: classes.icon,
                action: classes.action,
                filledInfo: classes.filledInfo
            }}
            elevation={6}
            variant="filled"
            icon={false}
            action={
                <IconButton onClick={onClose} className={clsx(globalClasses.noPadding)}>
                    <CircleCloseIcon primaryColor={theme.palette.text.secondary} fillColor={theme.palette.background.default} />
                </IconButton>
            }
            {...rest}
        >
            {children}
        </Alert>
    );
};

const Notifications: React.FC = () => {
    const classes = styles();

    const { notificationState, hideNotifications } = useContext(AppContext);
    const { showNotification, notificationType, notificationMessage, notificationTitle, onNotificationClose } = notificationState;

    const renderNotification = () => {
        switch (notificationType) {
            case NotificationTypes.ERROR:
                return (
                    <CustomAlert onClose={handleClose} severity="error">
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.WARNING:
                return (
                    <CustomAlert onClose={handleClose} severity="warning">
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.INFO:
                return (
                    <CustomAlert onClose={handleClose} severity="info">
                        {notificationTitle && <AlertTitle classes={{ root: classes.titleRoot }}>{notificationTitle}</AlertTitle>}
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.SUCCESS:
                return (
                    <CustomAlert onClose={handleClose} severity="success">
                        {notificationMessage}
                    </CustomAlert>
                );
        }
    };

    const handleClose = (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideNotifications();

        onNotificationClose && onNotificationClose();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            data-cy="pop-up-notification"
            open={showNotification}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            {renderNotification()}
        </Snackbar>
    );
};

export default Notifications;
