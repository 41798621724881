import { createTheme } from '@material-ui/core';

import palette from './pallete';
import typography from './typography';
import overrides from './overrides';

const defaultTheme = createTheme({
    spacing: 4,
    palette,
    typography,
    overrides,
    breakpoints: {
        values: {
            xs: 0,
            sm: 375,
            md: 769,
            lg: 992,
            xl: 1200
        }
    }
});

export const generateTheme = theme => {
    return createTheme({
        spacing: 4,
        palette,
        typography,
        overrides,
        breakpoints: {
            values: {
                xs: 0,
                sm: 375,
                md: 769,
                lg: 992,
                xl: 1200
            }
        },
        ...theme
    });
};

export default defaultTheme;
