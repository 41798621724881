import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        // maxWidth: 512,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            overflowX: 'hidden'
        }
    }
}));
